<template>
    <Menu
        as="div"
        class="relative inline-block text-left"
        data-spec-class="user-dropdown"
    >
        <div>
            <MenuButton
                class="focus:ring-indigo-500 flex items-center hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
                data-spec-class="user-dropdown__toggle"
            >
                <span data-spec-class="user-dropdown__name">
                    <slot />
                </span>
                <span class="sr-only">Open options</span>
                <ChevronDownIcon
                    class="h-5 w-5"
                    aria-hidden="true"
                />
            </MenuButton>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                class="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded bg-gray-600 text-blue-400 shadow ring-1 ring-black ring-opacity-5 focus:outline-none"
                data-spec-class="user-dropdown__menu"
            >
                <div class="py-1">
                    <MenuItem
                        data-spec-class="user-dropdown__menu-item"
                        v-for="link in links"
                        :key="link.id"
                    >
                        <BaseLink
                            class="block px-4 py-2 text-sm text-blue-400 hover:bg-gray-500 hover:text-blue-400 hover:no-underline"
                            :to="link.to"
                            :href="link.url"
                            data-spec-class="user-dropdown__menu-link"
                            :data-spec-id="`user-dropdown__menu-link--${link.id}`"
                        >
                            {{ link.label }}
                        </BaseLink>
                    </MenuItem>
                </div>

                <div class="py-1">
                    <MenuItem data-spec-class="user-dropdown__menu-item">
                        <a
                            href=""
                            @click.prevent="onLogout"
                            class="block px-4 py-2 text-sm text-blue-400 hover:bg-gray-500 hover:text-blue-400"
                            data-spec-class="user-dropdown__menu-link"
                            data-spec-id="user-dropdown__menu-link--logout"
                        >
                            {{ t('actions.logout') }}
                        </a>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>

    <Teleport to="body">
        <form
            method="POST"
            :action="`${baseUrl}/logout`"
            role="none"
            ref="logoutFormRef"
        >
            <BaseFieldCsrf />
        </form>
    </Teleport>
</template>

<script setup lang="ts">
import { ref, defineProps } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { useI18n } from 'vue-i18n';
import BaseFieldCsrf from './BaseFieldCsrf.vue';
import { LinkTo } from '../types';

defineProps<{
    links: LinkTo[];
}>();

const { t } = useI18n({ useScope: 'global' });

const logoutFormRef = ref(null);
const baseUrl = window.location.origin;

const onLogout = () => {
    logoutFormRef.value.submit();
};
</script>
