<template>
    <BaseField
        :label="label"
        :placeholder="placeholder"
        :hint="hint"
        :is-optional="isOptional"
        :name="name"
        :error-messages="errorMessagesTotal"
        data-spec-class="field-input"
        :label-wrapper-class="`flex justify-end flex-row-reverse`"
    >
        <div class="relative rounded-md">
            <input
                v-bind="$attrs"
                :type="TextFieldType.Checkbox"
                :name="name"
                :class="classObject"
                :placeholder="placeholder"
                :disabled="isDisabled"
                :checked="modelValue"
                ref="input"
                @change="$emit('update:modelValue', $event.target.checked)"
                @blur="hasAutoDirty ? vuelidateField?.$touch() : () => {}"
                :data-spec-class="dataSpecClass"
            />
        </div>
    </BaseField>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits, computed, ref, defineExpose } from 'vue';
import { TextFieldType, Theme } from '../types';

defineEmits(['update:modelValue']);

const input = ref(null);

function focus() {
    input.value.focus();
}

function select() {
    input.value.select();
}

defineExpose({ focus, select });

interface Props {
    label?: string;
    hint?: string;
    placeholder?: string;
    isOptional?: boolean;
    isDisabled?: boolean;
    modelValue?: boolean;
    vuelidateField?: any;
    errorMessages?: string[];
    name: string;
    dataSpecClass?: string;
    hasAutoDirty?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    label: '',
    hint: '',
    placeholder: '',
    isOptional: false,
    isDisabled: false,
    modelValue: false,
    vuelidateField: undefined,
    errorMessages: [],
    dataSpecClass: 'field-input__check',
    hasAutoDirty: true,
});

const vuelidateErrors = computed(() => {
    if (props.vuelidateField?.$errors.length) {
        return props.vuelidateField.$errors.map((error) => error.$message);
    }

    return [];
});

const errorMessagesTotal = computed(() => [...vuelidateErrors.value, ...props.errorMessages]);

const theme = computed(() => {
    if (props.isDisabled) {
        return Theme.Disabled;
    }
    if (errorMessagesTotal.value.length) {
        return Theme.Danger;
    }

    return Theme.Normal;
});

const themeBase = computed(() => {
    const defaultThemeBase = 'h-4 w-4 mr-3 rounded';
    return defaultThemeBase;
});

const themeNormal = computed(() => {
    const defaultThemeNormal = 'border-gray-300 text-indigo-600 focus:ring-indigo-600';
    return defaultThemeNormal;
});

const classObject = computed(() => ({
    [themeBase.value]: true,
    [themeNormal.value]: theme.value === Theme.Normal,
    'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-500':
        theme.value === Theme.Disabled,
}));
</script>

<script lang="ts">
export default {
    inheritAttrs: false,
};
</script>
