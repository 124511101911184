<template>
    <BaseField
        :label="label"
        :hint="hint"
        :is-optional="isOptional"
        :name="name"
        :error-messages="errorMessages"
        data-spec-class="field-select-search"
    >
        <Combobox
            as="div"
            v-model="selectedOption"
            :disabled="isDisabled"
        >
            <div class="relative">
                <ComboboxInput
                    :name="name"
                    :class="classObject"
                    @change="query = $event.target.value"
                    :display-value="(option) => option?.name"
                    data-spec-class="field-select-search__input"
                    :data-spec-value="selectedOption?.id"
                    @blur="vuelidateField && vuelidateField.$touch()"
                    :disabled="isDisabled"
                />
                <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                    data-spec-class="field-select-search__button"
                >
                    <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </ComboboxButton>

                <ComboboxOptions
                    v-if="filteredOptions.length > 0"
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    data-spec-class="field-select-search__options"
                >
                    <ComboboxOption
                        v-for="option in filteredOptions"
                        :key="option.id"
                        :value="option"
                        as="template"
                        v-slot="{ active, selected }"
                    >
                        <li
                            :class="[
                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            ]"
                            data-spec-class="field-select-search__option"
                        >
                            <span :class="['block truncate', selected && 'font-semibold']">
                                {{ option.name }}
                            </span>

                            <span
                                v-if="selected"
                                :class="[
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                    active ? 'text-white' : 'text-blue-600',
                                ]"
                            >
                                <CheckIcon
                                    class="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </span>
                        </li>
                    </ComboboxOption>
                </ComboboxOptions>
            </div>
        </Combobox>
    </BaseField>
</template>

<script setup lang="ts">
import { computed, ref, defineProps, withDefaults, defineEmits, watch } from 'vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue';
import { OptionItem, Theme } from '../types';

interface Props {
    label?: string;
    hint?: string;
    isOptional?: boolean;
    isDisabled?: boolean;
    vuelidateField?: any;
    options: OptionItem[];
    modelValue?: string;
    name: string;
}

const props = withDefaults(defineProps<Props>(), {
    label: '',
    hint: '',
    isOptional: false,
    isDisabled: false,
    modelValue: undefined,
    vuelidateField: undefined,
});

const emit = defineEmits(['update:modelValue']);

const selectedOption = ref('undefined');

watch(selectedOption, async (newSelectedOption: OptionItem) => {
    emit('update:modelValue', newSelectedOption.id);
});

if (props.modelValue) {
    const foundOption = props.options.find((option: OptionItem) => option.id === props.modelValue);
    selectedOption.value = foundOption;
}

const query = ref('');
const filteredOptions = computed(() =>
    query.value === ''
        ? props.options
        : props.options.filter((option) =>
              option.name.toLowerCase().includes(query.value.toLowerCase())
          )
);

const errorMessages = computed(() => {
    if (props.vuelidateField && props.vuelidateField.$errors.length) {
        return props.vuelidateField.$errors.map((error) => error.$message);
    }

    return [];
});

const theme = computed(() => {
    if (props.isDisabled) {
        return Theme.Disabled;
    }
    if (errorMessages.value.length) {
        return Theme.Danger;
    }
    return Theme.Normal;
});

const classObject = computed(() => ({
    'block w-full rounded-md shadow-sm sm:text-sm': true,
    'border-gray-300 focus:border-blue-500 focus:ring-blue-500': theme.value === Theme.Normal,
    'border-red-light pr-10 text-red-dark placeholder-red-light focus:border-red focus:outline-none focus:ring-red':
        theme.value === Theme.Danger,
    'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-500':
        theme.value === Theme.Disabled,
}));
</script>
