<template>
    <div data-spec-class="field">
        <div :class="labelWrapperClass">
            <div
                v-if="label || isOptional"
                class="flex justify-between"
            >
                <label
                    v-if="label"
                    :for="name"
                    class="block text-sm font-medium text-gray-700 mb-1"
                    data-spec-class="field__label"
                >
                    {{ label }}
                </label>
                <span
                    v-if="isOptional"
                    class="text-sm text-gray-500"
                    data-spec-class="field__optional"
                >
                    {{ t('forms.optional') }}
                </span>
            </div>
            <slot />
        </div>

        <p
            v-if="hint"
            class="mt-2 text-sm text-gray-500"
            data-spec-class="field__hint"
        >
            {{ hint }}
        </p>

        <p
            v-if="errorMessages.length"
            class="mt-2 text-sm text-red"
            data-spec-class="field__error"
        >
            <ul>
                <li
                    v-for="(errorMessage, i) in errorMessages"
                    :key="i"
                >
                    {{ errorMessage }}
                </li>
            </ul>
        </p>
    </div>
</template>

<script setup lang="ts">
import {
    withDefaults, defineProps, defineEmits,
} from 'vue';
import { useI18n } from 'vue-i18n';

defineEmits(['update:modelValue']);

const { t } = useI18n();

interface Props {
    label?: string,
    labelWrapperClass?: string,
    hint?: string,
    isOptional?: boolean,
    errorMessages?: string[],
    name: string,
}

withDefaults(defineProps<Props>(), {
    label: '',
    labelWrapperClass: '',
    hint: '',
    isOptional: false,
    vuelidateField: undefined,
    errorMessages: [],
});
</script>
